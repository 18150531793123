.theme-Light {
  --body-background: #e3f2e3;
  --gradient-button: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --dark-text: #000000;
  --select-text: #141414c2;
  --light-text: #ffff;
  --text-color: #000000;
  --common-text: #ffff;
  --login-page-text: #000000;
  --profile-edit-bg: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --header-background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --header-svg-background: #ffffff19;
  --header-report-button: #98d19a;
  --header-border: #007105de;

  --sidebar-background: transparent linear-gradient(0deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --sidebar-svg-background: #ffffff19;
  /* --sidebar-border: #ffffff19; */
  --sidebar-border: #007105de;
  --sidebar-bootom-time-background: #98d19a;
  --sidebar-menu-box-button: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --sidebar-menu-box-background: #dbffde;
  --sidebar-menu-button: #039153;
  --sidebar-svg-background-hover: #ffffff66;
  --sidebar-menu-box-text: #8a8a8a;

  --table-background: #e3f2e3;
  --table-header-background: #b6e5b8;
  --table-border: #98d19a;
  --table-header-text: #000000;
  --table-action-button-background: #009856;
  --table-action-button-background-hover: #61af63;
  --analysis-table-action-button: #98d19a;
  --script-active-tab: #009856;
  --fav-script-active-tab: #009856;
  --Download-button-background: #e3f2e3;
  --Download-button-border: #009856;
  --order-report-button-background: #b6e5b8;
  --order-report-button-active-background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
  --chart-button-background: #ffffff19;
  --report-order-button-background: #b6e5b8;

  --modal-body-background: #cce6cc;
  --modal-header-background: #b6e5b8;
  --modal-header-svg-background: #009856;
  --modal-header-button-background: #98d19a;
  --modal-header-button-active-background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%);
  --modal-intraday-button--background: #98d19a;
  --modal-intraday-button-active-background: #44a147;
  --modal-button-border: #009856;
  --modal-active-button-border: #44a147;
  --positional-button-border: #009856;
  --modal-general-shortcut-header: #98d19a;
  --modal-shortcut-button: #44a147;
  --modal-shortcut-button-hover: #009856;
  --modal-replace-key-background: #b6e5b8;
  --modal-replace-button-background: #cce6cc;
  --modal-togel-button: #98d19a;
  --modal-togel-button-active: #44a147;
  --modal-relode-button-background: #44a147;
  --modal-border: #98d19a;
  --grid-modal-active-button-background: #009856;
  --grid-modal--button-border: #009856;
  --recent-index-modal-script-border: #98d19a;
  --recent-index-modal-script-number-box: #009856;
  --modal-sorting-button: #b6e5b8;
  --modal-sorting-inner-text-bg: #009856;
  --download-modal-intraday-button-svg: #b6e5b8;
  --download-modal-intraday-active-button-svg: #44a147;
  --modal-opacity-slider: #ffffff;
  --chart-modal-dropdown-background: #98d19a;

  --order-list-header-background: #b6e5b8;
  --order-list-header-button-background: #009856;

  --textfield-background: #dbffde;
  --textfield-placeholder: #000000;
  --text-field-border: #98d19a;
  --text-field-focus: #009856;

  --menu-box-buttons: #44a147 !important;
  --menu-box-buttons-hover: #44a147;
  --menu-box-background: #cce6cc;

  --chart-background: #ffffff;
  --chart-x-grid: #000000;
  --chart-header-background: #b6e5b8;
  --chart-header-dot-svgbackground: #2b807d;
  --chart-header-closed-background: #44a147;
  --chart-header-closed-svg-background: #009856;
  --chart-dropdown-background: #cce6cc;
  --chart-dropdown-button: #b6e5b8;

  --pagination-button-background: #283142;
  --pagination-active-button-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%;

  --scroll-bg: #dbffde;
  --scroll-thumb: #98d19a;
  --scroll-hover: #44a147;

  --header-search-icon-fill: #fff;
  --header-chart-icon-fill: #fff;
  --header-setting-icon-fill: #fff;
  --header-zoom-icon-fill: #fff;
  --header-bar-icon-fill: #fff;
  --header-analysis-icon-fill: #fff;
  --sidebar-i-svg: #fff;
  --sidebar-bxlinechart-svg: #fff;
  --sidebar-linechart-svg: #fff;
  --sidebar-flip-svg: #fff;
  --setting-modal-header-minus-svg: #fff;
  --setting-modal-header-close-svg: #fff;

  --sorting-button: #b6e5b8;
  --sorting-inner-text-bg: #009856;
  --sorting-button-border: #009856;
  --delete-model: #b6e5b8;

  --modal-seprator: #80dd83;
  --modal-toggle-bg: #80dd83;
  --modal-active-bg: #009856;
  --grid-active:#009856;
  --chart-color-dropdown-active-border: #287c85;
}

.theme-Medium {
  --body-background: #12161f;
  --gradient-button: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
  --dark-text: #ffff;
  --select-text: #5a76b4;
  --light-text: #ffff;
  --text-color: #5a76b4;
  --common-text: #ffff;
  --script-active-tab: #8285F9;
  --fav-script-active-tab: #8285F9;
  --profile-edit-bg: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0%;

  --header-background: #141922;
  --header-svg-background: #1d2330;
  --header-report-button: #98d19a;
  --header-border: #202748;

  --sidebar-background: #141922;
  --sidebar-svg-background: #1d2330;
  --sidebar-border: #202748;
  --sidebar-menu-box-button: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%;
  --sidebar-menu-box-background: #000000;
  --sidebar-svg-background-hover: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%);
  --sidebar-bootom-time-background: #202748;
  --sidebar-menu-button: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;

  --table-background: #000000;
  --table-header-background: #1d2330;
  --table-border: #283142;
  --table-header-text: #5a76b4;
  --table-action-button-background: #283142;
  --table-action-button-background-hover: #5a76b4;
  --analysis-table-action-button: #1d2330;

  --Download-button-background: #2ebe54;
  --Download-button-border: #2ebe54;
  --order-report-button-background: #283142;
  --order-report-button-active-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
  --chart-button-background: #283142;
  --report-button-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
  --report-order-button-background: #283142;

  --modal-body-background: #1d2330;
  --modal-header-background: #1d2330;
  --modal-header-svg-background: #283142;
  --modal-header-button-background: #181d28;
  --modal-header-button-active-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
  --modal-intraday-button--background: #1d2330;
  --modal-intraday-button-active-background: #3a6dd6;
  --modal-button-border: #42506f;
  --modal-active-button-border: #3a6dd6;
  --positional-button-border: #3a6dd6;
  --modal-general-shortcut-header: #283142;
  --modal-shortcut-button: #283142;
  --modal-replace-key-background: #283142;
  --modal-replace-button-background: #42506f;
  --modal-shortcut-button-hover: #283142;
  --modal-togel-button: #283142;
  --modal-togel-button-active: #3a6dd6;
  --modal-relode-button-background: #42506f;
  --modal-border: #42506f;
  --grid-modal-active-button-background: #263e6a;
  --grid-modal--button-border: #5a76b4;
  --recent-index-modal-script-border: #42506f;
  --recent-index-modal-script-number-box: #42506f;
  --modal-sorting-button: #283142;
  --modal-sorting-inner-text-bg: #42506f;
  --download-modal-intraday-button-svg: #42506f;
  --download-modal-intraday-active-button-svg: #3a6dd6;
  --modal-opacity-slider: #3a6dd6;
  --chart-modal-dropdown-background: #5897fb;

  --order-list-header-background: #283142;
  --order-list-header-button-background: #181d28;

  --textfield-background: #1d2330;
  --textfield-placeholder: #7b91c2;
  --text-field-border: #42506f;
  --text-field-focus: #42506f;

  --menu-box-buttons: #1d2330 !important;
  --menu-box-buttons-hover: #1d2330;
  --menu-box-background: #283142;

  --chart-background: #283142;
  /* --chart-background: #1e2431; */
  --chart-x-grid: #fff;
  --chart-header-background: #1e2431;
  --chart-header-dot-svgbackground: #42506f;
  --chart-header-closed-background: #916aff;
  --chart-header-closed-svg-background: #6345b8;
  --chart-dropdown-background: #283142;
  --chart-dropdown-button: #1e2431;

  --pagination-button-background: #283142;
  --pagination-active-button-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%;

  --scroll-bg: #1d2330;
  --scroll-thumb: #526ba2;
  --scroll-hover: #526ba2;

  --header-search-icon-fill: #79ffd4;
  --header-chart-icon-fill: #79ffd4;
  --header-setting-icon-fill: #79ffd4;
  --header-zoom-icon-fill: #79ffd4;
  --header-bar-icon-fill: #79ffd4;
  --header-analysis-icon-fill: #79ffd4;
  --sidebar-i-svg: #79ffd4;
  --sidebar-bxlinechart-svg: #79ffd4;
  --sidebar-linechart-svg: #79ffd4;
  --sidebar-flip-svg: #79ffd4;
  --setting-modal-header-minus-svg: #79ffd4;
  --setting-modal-header-close-svg: #79ffd4;
  --delete-model: #b6e5b8;

  --modal-seprator: #333333;
  --modal-toggle-bg: #272f3f;
  --modal-active-bg: transparent linear-gradient(180deg, #5863E5 0%, #8789FB 100%);
  --grid-active:#5a76b4;
  --chart-color-dropdown-active-border: #5863e5;
}

.theme-Dark {
  --body-background: #000000;
  --gradient-button: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
  --dark-text: #ffff;
  --select-text: #8a8a8a;
  --light-text: #000000;
  --login-page-text: #9d9797;
  --text-color: #8a8a8a;
  --common-text: #000000;

  --profile-edit-bg: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);

  --header-background: #000000;
  --header-svg-background: #ffffff19;
  --header-report-button: #98d19a;
  --header-border: #191919;

  --sidebar-background: #000000;
  --sidebar-svg-background: #ffffff19;
  --sidebar-border: #191919;
  --sidebar-menu-box-button: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%) 0% 0% no-repeat padding-box;
  --sidebar-menu-box-background: #131111;
  --sidebar-menu-button: #039153;
  --sidebar-svg-background-hover: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%) 0% 0% no-repeat padding-box;
  --sidebar-menu-box-text: #8a8a8a;
  --sidebar-bootom-time-background: #ffffff19;

  --table-background: #000000;
  --table-header-background: #000000;
  --table-border: #191919;
  --table-header-text: #8a8a8a;
  --table-action-button-background: #ffffff19;
  --table-action-button-background-hover: #8a8a8a;

  --Download-button-background: #2ebe54;
  --Download-button-border: #2ebe54;
  --order-report-button-background: #232528;
  --order-report-button-active-background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
  --chart-button-background: #232528;
  --report-button-background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
  --report-order-button-background: #232528;

  --modal-body-background: #232323;
  --modal-header-background: #222222;
  --modal-header-svg-background: #ffffff19;
  --modal-header-button-background: #1d1d1c;
  --modal-header-button-active-background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
  --modal-intraday-button--background: #000000;
  --modal-intraday-button-active-background: #a5ff7c;
  --modal-button-border: #191919;
  --modal-active-button-border: #a5ff7c;
  --positional-button-border: #3a6dd6;
  --modal-general-shortcut-header: #232528;
  --modal-shortcut-button: #ffffff19;
  --modal-replace-key-background: #1a1b1d;
  --modal-replace-button-background: #232528;
  --modal-shortcut-button-hover: #ffffff19;
  --modal-togel-button: #ffffff19;
  --modal-togel-button-active: #a5ff7c;
  --modal-relode-button-background: #232528;
  --modal-border: #191919;
  --grid-modal-active-button-background: #79ffd452;
  /* --grid-modal--button-border: #232528; */
  --grid-modal--button-border: #79ffd452;
  --recent-index-modal-script-border: #191919;
  --recent-index-modal-script-number-box: #ffffff19;
  --modal-sorting-button: #ffffff19;
  --modal-sorting-inner-text-bg: #232528;
  --download-modal-intraday-button-svg: #ffffff19;
  --download-modal-intraday-active-button-svg: #a5ff7c;
  --modal-opacity-slider: #a5ff7c;
  --chart-modal-dropdown-background: #5897fb;

  --order-list-header-background: #131111;
  --order-list-header-button-background: #ffffff19;

  --textfield-background: #000000;
  --textfield-placeholder: #7B91C2;
  --textfield-placeholder: #8a8a8a;
  --text-field-border: #191919;
  --text-field-focus: #191919;

  --menu-box-buttons: #232528 !important;
  --menu-box-buttons-hover: #232528;
  --menu-box-background: #131111;

  --chart-background: #212121;
  /* --chart-background: #131111; */
  --chart-x-grid: #fff;
  --chart-header-background: #131111;
  --chart-header-dot-svgbackground: #42506f;
  --chart-header-closed-background: #91c27a;
  --chart-header-closed-svg-background: #366123;
  --chart-dropdown-background: #283142;
  --chart-dropdown-button: #1e2431;

  --pagination-button-background: #283142;
  --pagination-active-button-background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%;

  --scroll-bg: #232528;
  --scroll-thumb: #616161;
  --scroll-hover: #506745;

  --header-search-icon-fill: #79ffd4;
  --header-chart-icon-fill: #79ffd4;
  --header-setting-icon-fill: #79ffd4;
  --header-zoom-icon-fill: #79ffd4;
  --header-bar-icon-fill: #79ffd4;
  --header-analysis-icon-fill: #79ffd4;
  --sidebar-i-svg: #79ffd4;
  --sidebar-bxlinechart-svg: #79ffd4;
  --sidebar-linechart-svg: #79ffd4;
  --sidebar-flip-svg: #79ffd4;
  --setting-modal-header-minus-svg: #a5ff7c;
  --setting-modal-header-close-svg: #a5ff7c;
  --script-active-tab: #232528;
  --fav-script-active-tab: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
  --sorting-button: #283142;
  --sorting-inner-text-bg: #42506f;
  --sorting-button-border: #283142;
  --delete-model: #b6e5b8;

  --modal-seprator: #131313;
  --modal-toggle-bg: #131313;
  --modal-active-bg: transparent linear-gradient(180deg, #8de962 0%, #67efc4 100%);
  --grid-active:#79ffd4;
  --chart-color-dropdown-active-border: #a5ff7c;
}