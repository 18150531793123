@media screen and (min-width: 1600px) and (max-width: 2560px) {
  .table-sec .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    width: 100%;
  }
  .open-order-report .MuiDataGrid-virtualScrollerRenderZone,
  .analysis-table .MuiDataGrid-virtualScrollerRenderZone,
  .index-management-table .MuiDataGrid-virtualScrollerRenderZone,
  .user-management-table .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }
  .open-order-report .MuiDataGrid-row,
  .analysis-table .MuiDataGrid-row,
  .index-management-table .MuiDataGrid-row,
  .user-management-table .MuiDataGrid-row {
    width: 100% !important;
  }
  .open-order-report .MuiDataGrid-columnHeadersInner,
  .index-management-table .MuiDataGrid-columnHeadersInner,
  .user-management-table .MuiDataGrid-columnHeadersInner {
    width: 100% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:first-child {
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(6) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(7) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(8) {
    min-width: 20% !important;
    max-width: 20% !important;
  }

  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:first-child {
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    min-width: 11% !important;
    max-width: 11% !important;
  }
  .open-order-report .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .open-order-report
    .MuiDataGrid-virtualScrollerRenderZone
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-last-child(2) {
    justify-content: flex-start !important;
  }
  /* analysis start */
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(6) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(7) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .analysis-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  /* analysis end */

  /* index start */

  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .index-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(6) {
    min-width: 20% !important;
    max-width: 20% !important;
  }

  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .index-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  /* index- management end */

  /* user management start */
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(6) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(7) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:first-child {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-management-table .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    min-width: 20% !important;
    max-width: 20% !important;
  }
  /* user management end */

  /* user-tagged-scripts-performance start */

  .user-tagged-scripts-performance .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }

  .user-tagged-scripts-performance .MuiDataGrid-row {
    width: 100% !important;
  }

  .user-tagged-scripts-performance .MuiDataGrid-columnHeadersInner {
    width: 100% !important;
  }

  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:first-child {
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(4) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(6) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(7) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(8) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(9) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:nth-child(10) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:first-child {
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(2) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(3) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(4) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(5) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(6) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(7) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(8) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(9) {
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-tagged-scripts-performance
    .MuiDataGrid-row
    .MuiDataGrid-cell:nth-child(10) {
    min-width: 15% !important;
    max-width: 15% !important;
  }
  /* user-tagged-scripts-performance end */
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .small-chart-box .chart-header-left .chart-text {
    font-size: 12px;
    line-height: 19px;
    margin: 0 7px 0 0;
  }
}
@media screen and (max-width: 1365px) {
  .small-chart-box .round-btn-main .round-btn {
    min-width: 20px;
    max-width: 20px;
    height: 20px;
  }
  .small-chart-box .chat-header-right .red-btn-main .buy-btn {
    min-width: 50px;
    margin: 0px 5px 0px 0px;
  }
  .main-ul .bsw-search {
    display: none;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .small-chart-box .chart-header-left .chart-text {
    font-size: 12px;
    line-height: 19px;
    margin: 0 7px 0 0;
  }
}
@media screen and (min-width: 992px) {
  .header-right .main-ul .main-li:last-child {
    display: none;
  }
  .main-ul .main-li.custom-menu-logo-box {
    margin-right: 0;
  }

  .tracking-chart-sub-header {
    display: none;
  }
}
/* / ipad pro / */
@media screen and (min-width: 992px) and (max-width: 1279px) {
  /* .main-content-sec{
        height: 100%;
    } */
  .small-chart-box .chart-header-left .chart-text {
    font-size: 10px;
    line-height: 17px;
    margin: 0 7px 0 0;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content-sec {
    height: 100%;
  }
  .main-logo {
    width: 150px;
    /* width: 38px; */
  }
  .main-li .cus-search-input .MuiOutlinedInput-input {
    min-width: calc(227px - 48px);
  }
  .grid-list-btn-sec {
    margin: 0 10px 0 0px;
  }
  .main-ul .main-li {
    margin-right: 10px;
  }
}
/* / phone and tablets / */
@media screen and (max-width: 991px) {
  .custom-report-chart {
    margin-left: 0;
  }
  .custom-report-chart .chart-repot-btn {
    width: 70px;
    height: 40px;
    font-size: 14px;
  }
  .mini-chart-modal-box {
    width: calc(100% - 10px) !important;
  }
  .custom-chart-body div div {
    width: 100% !important;
  }
  .main-content-sec .dashboard-chart-mobile {
    overflow-y: auto;
  }
  .custom-tracking-header .header-exit-all-main,
  .custom-tracking-header .buy-sell-watch-label {
    display: none;
  }
  .toggle-menu-sec {
    max-height: 520px;
    overflow-y: auto;
  }

  .small-chart-box .chart-header-left .chart-text {
    font-size: 10px;
    line-height: 17px;
    margin: 0 5px 0 0;
  }
  .small-chart-box .chat-header-right .red-btn-main .buy-btn {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 5px 0px 0px;
  }
  /* .small-chart-box .round-btn-main .round-btn {
        min-width: 20px;
        max-width: 20px;
        height: 20px;
    } */
  .main-content-sec {
    height: 100%;
  }
  .main-content-sec {
    padding: 15px;
  }
  .m-modal {
    width: 700px !important;
  }
  .main-logo {
    width: 150px;
    /* width: 38px; */
  }
  /* .grid-list-btn-sec, .script-btn{
        display: none;
    } */
  .header-right .main-ul .main-li:nth-last-child(2) {
    display: block;
  }
  .header-right .main-ul .main-li:last-child {
    display: block;
  }
  .sidebar-main {
    position: fixed;
    right: -80px;
  }
  .sidebar-main.active {
    right: 0px;
  }
  .main-content-sec {
    margin-right: 0;
    /* padding: 15px; */
  }
  .custom-tool-show {
    display: none;
  }
  .mobile-tools {
    display: block !important;
  }
  .custom-market-btn {
    display: none;
  }
  .header-search-box {
    width: 280px;
  }
  .custom-sidebar-mob {
    z-index: 9999;
  }
  .main-ul .main-li.sidbar-btn {
    margin-right: 0;
  }

  .footer-sec {
    right: 15px;
  }

  .main-content-sec .dashboard-chart-main {
    height: calc(100vh - 217px);
  }
}

/* / tablets / */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .custom-modal-header .chart-header-main {
    padding: 13px 25px;
  }
  .main-ul .bsw-search {
    display: flex;
  }
  .one-column-wrapper .chart-header-left .chart-text,
  .two-column-wrapper .chart-header-left .chart-text {
    font-size: 20px;
    line-height: 29px;
    margin: 0 13px 0 0;
  }
  .one-column-wrapper .chart-header-main,
  .two-column-wrapper .chart-header-main {
    padding: 13px 20px;
  }
  .profile-input-wrapper .cus-input-grp {
    width: 354px;
  }
  .report-tab-content-main .tabs-content-title {
    background: #1d2330;
    width: 190px;
    font-size: 16px;
  }
  .editRepot-download-sec .primary-btn-main .edit-download-btn {
    width: 120px;
    font-size: 16px;
    line-height: 25px;
  }
  .main-logo {
    margin: 5px 0 38px 0px;
  }
  .modal-body {
    max-height: 490px;
    overflow: auto;
  }
  .report-intra-pos-main .time-frame-left {
    width: 75%;
  }
}

/* / phone / */
@media (max-width: 767px) {
  .custom-theme .custom-menu-btn .MuiListItemText-primary {
    font-size: 14px;
  }
  .custom-register .password-input-grp {
    width: 100%;
  }
  .custom-login-inner h1 {
    line-height: 25px;
  }
  .custom-login-inner .main-logo,
  .register-inner-main .main-logo {
    margin-bottom: 15px;
  }
  .custom-login-inner .login-sub-heading {
    margin-bottom: 20px;
  }

  .custom-reset-inner .reset-sub-heading {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .main-chart-box .chart_ohcl .MuiTypography-caption {
    font-size: 12px;
  }

  .custom-modal-header .chart-header-left .stock-title-box p {
    line-height: 39px;
  }

  .main-chart-box .chart_ohcl .MuiTypography-caption {
    font-size: 12px;
  }

  .custom-modal-header .chart-header-left .stock-title-box p {
    line-height: 39px;
  }

  .custom-modal-header .close-img {
    width: 12px;
    height: 12px;
  }

  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-cell:nth-child(2),
  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-columnHeader:nth-child(2),
  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-cell:nth-child(4),
  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-columnHeader:nth-child(4) {
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
  }

  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-cell:nth-child(3),
  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .MuiDataGrid-columnHeader:nth-child(3) {
    width: 160px !important;
    min-width: 160px !important;
    max-width: 160px !important;
  }

  .custom-index-table
    .css-12u49z-MuiDataGrid-root
    .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
    width: 900px !important;
  }

  .custom-dots-img {
    height: 20px;
    margin-left: 5px;
  }
  .round-plus-box .download-btn {
    margin: 0 5px;
  }
  .custom-tags-btn .buy-btn,
  .custom-modal-header .custom-tags-btn .buy-btn {
    margin: 0;
    font-size: 12px;
    min-width: 45px;
    height: 20px;
  }

  .select-medium-theme
    .modal-header-sec
    .chart-header-time
    .chart-updated-time-text {
    font-size: 12px;
    line-height: 0px;
    padding-top: 7px;
  }
  .download-container .download-btn {
    display: none;
  }
  .tag-option ul li:last-child {
    display: block;
  }
  .custom-chart-header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .chart-header-left {
    flex-direction: column;
  }
  .custom-header-right .main-ul .main-li {
    margin-right: 12px;
  }
  .main-ul .main-li.sidbar-btn {
    margin-right: 0;
  }
  .category-select-box .common-dropdown {
    min-width: 130px;
    width: 130px;
  }
  .stock-title-box {
    /* flex-direction: column; */
    width: 100%;
  }
  .chart-header-left .stock-title-box .chart-text,
  .stock-trade-price .chart-text,
  .custom-modal-header .chart-header-left .chart-text {
    font-size: 12px;
    margin-right: 7px;
    line-height: 18px;
  }
  .desktop-view-chart {
    display: none;
  }
  .mobile-view-chart {
    display: block;
    min-width: 40px;
    height: 40px;
    padding: 12px;
    margin-right: 12px;
  }
  .hide-mobile-search,
  .main-ul .search-btn {
    display: none;
  }
  .header-search-box.MuiAutocomplete-root .MuiOutlinedInput-root input {
    height: 40px;
  }
  .login-inner-main {
    min-width: auto;
    padding: 10px;
  }
  .register-inner-main .register-input-flex {
    flex-direction: column;
  }
  .profile-input-wrapper .cus-input-grp,
  .change-password-inner-main .cus-input-grp {
    width: 100%;
    margin: 0 0px 20px 0;
  }
  .drawer-btn {
    display: inline-flex;
  }
  /* .sidebar-main{
        position: fixed;
        right: -80px;
    }
    .sidebar-main.active{
        right: 0px;
    } */
  .main-content-sec {
    margin-right: 0;
    /* padding: 15px; */
  }
  .footer-sec {
    right: 25px;
    width: 100%;
  }
  .modal-wrapper {
    width: 360px !important;
  }
  .drawing-modal-wrapper {
    width: 135px !important;
  }
  .horizon .drawing-modal-wrapper {
    width: 360px !important;
  }
  .modal-wrapper .modal-header {
    padding: 8.5px 8px 8.5px 8.5px;
  }
  .body-p {
    padding: 15px;
  }
  .index-input-wrapper .index-input-grp {
    margin: 20px 0px 0 0;
    width: 100%;
  }
  .modal-wrapper .model-header-left h6 {
    font-size: 14px;
    line-height: 23px;
  }
  .tab-content-main .tabs-content-title {
    font-size: 12px;
    line-height: 15px;
    width: calc(100% / 4);
  }
  .tabs-content-panel,
  .tabpanel-simple {
    padding: 15px 15px;
    position: relative;
  }
  .modal-sub-title {
    font-size: 14px;
    line-height: 21px;
  }
  .modal-ul-li .modal-ul .modal-li {
    font-size: 12px;
    line-height: 18px;
  }

  .time-frame-left .time-frame-ul .time-frame-li {
    margin: 0 10px 10px 0px;
  }
  .style-sec .modal-ul-li .modal-ul .modal-li {
    min-width: 100px;
    justify-content: center !important;
    margin-bottom: 10px;
  }
  .default-setting-main .style-sec .modal-ul-li .modal-ul .modal-li {
    min-width: 100px;
    margin-right: 0px;
  }
  .chart-color-box {
    width: 100%;
    margin: 0 0px 20px 0;
  }
  .chart-color-wrapper .crosshair-sec:nth-last-child(2) {
    margin-bottom: 20px;
  }
  .crosshair-sec {
    margin: 0 0px 20px 0;
    width: 100%;
    justify-content: space-between;
  }
  .m-modal {
    width: 360px !important;
  }
  .m-modal .chart-header-main {
    padding: 10px 10px;
  }
  .m-modal .modal-header-sec .chart-header-left .chart-text {
    font-size: 13px;
    line-height: 20px;
  }
  .m-modal .chart-header-left .chart-text {
    margin: 0 7px 0 0;
  }
  .m-modal .modal-header-sec .red-btn-main .buy-btn {
    min-width: 55px;
    margin: 0px 7px 0px 7px;
    font-size: 14px;
    line-height: 20px;
  }
  .m-modal .round-btn-main .download-btn {
    margin-right: 7px;
  }
  .m-modal .close-img {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
  .main-ul .main-li .btn-bg {
    min-width: 40px !important;
    min-height: 40px;
  }
  .main-li .side-time {
    font-size: 9px;
    line-height: 12px;
  }
  .head-btn-img {
    width: 15px;
    height: 15px;
  }
  .sidebar-second-sec .main-ul .main-li:first-child {
    display: none;
  }
  .profile-input-wrapper {
    margin-left: 5px;
    margin-right: 5px;
  }
  .login-bg .change-password-inner-main {
    margin: 0px 15px 15px 15px;
    min-width: 290px;
  }
  .login-bg .change-password-inner-main h1 {
    font-size: 25px;
  }
  .tab-wrapper {
    display: block;
  }
  .performance-report-top-btn .report-tab-content-title {
    width: calc(100% / 2);
  }
  .editRepot-download-sec {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    gap: 15px;
  }
  .performance-report-wrap .tab-content-main .tab-body {
    margin-top: 60px;
  }
  .performance-report-wrap .edit-download-btn {
    margin-left: 0px;
    width: 100px;
    height: 30px;
    font-size: 10px;
  }
  .table-sec .MuiDataGrid-columnHeaders {
    min-height: 40px !important;
    max-height: 45px !important;
  }
  .table-sec .MuiDataGrid-cellContent {
    font-size: 15px !important;
  }
  .name-wrap .tag-main {
    width: 31%;
    margin: 0px 0px 10px 0px;
  }
  .name-wrap .sorting-time-frame {
    gap: 25px;
    margin: 0;
  }
  .sorting-time-frame .tag-main {
    margin: 0;
    width: 90px;
    padding: 10px 18px;
  }
  .sorting-time-frame .arrow-round-sec {
    margin-right: 0px;
  }
  .default-setting-main .time-frame-content {
    display: block;
  }
  .default-setting-main .time-frame-right {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .default-setting-main .time-frame-left {
    width: 100%;
  }
  .default-setting-main .tabpanel-simple {
    height: calc(100vh / 1.9);
    overflow: auto;
  }
  .default-setting-main .chart-color-setting {
    display: block;
    margin-top: 10px;
  }
  .chart-color-setting .common-dropdown {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
  .default-setting-main .title {
    width: 100px;
  }
  .report-modal .modal-ul-li .modal-ul .modal-li {
    width: 90px;
    /* margin: 0; */
  }
  .view-cleare-sec .report-buy-sell-btn {
    width: 90px;
    height: 37px;
    font-size: 12px;
  }
  .report-modal-range-box .form-control input {
    width: 100%;
  }
  .report-buy-sell-sec .report-buy-sell-btn {
    font-size: 12px;
    height: 40px;
  }
  .select-medium-theme .modal-delete .delete-modal-inner-main {
    width: 290px;
    height: 230px;
  }
  .orientation-option.horizon .modal-body {
    overflow-x: scroll;
  }
  .header-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .custom-toggle-list .toggle-list-btn svg,
  .custom-toggle-list .toggle-list-btn img {
    width: 18px;
  }
  .toggle-menu-sec
    .custom-toggle-list
    .toggle-menu-text
    .MuiListItemText-primary {
    font-size: 14px;
  }
  .custom-toggle-list .toggle-list-btn {
    height: 40px;
  }
  .tag-main .tag-label {
    font-size: 11px;
  }
  .name-wrap .cus-lablel {
    font-size: 14px;
  }
  .report-intra-pos-sec .report-intra-pos-btn {
    font-size: 14px;
  }
  .modal-body {
    max-height: 380px;
    overflow: auto;
  }
  .date-range-sec .to-text {
    font-size: 14px;
  }
  .download-chart-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .script-list-main {
    max-width: 165px;
    width: 55%;
    padding-right: 5px;
  }
  .input-box {
    font-size: 13px;
    padding: 4.5px 8.5px 7.5px 40px;
  }
  .recent-index-wrapper .label-no {
    width: 30px;
    height: 35px;
  }
  .gap-thickness-color-sec {
    flex-direction: column;
  }
  .grid-modal-main .cus-input-grp {
    display: flex;
    justify-content: space-between;
  }
  .grid-modal-main .gap-thickness-color-sec .gap-sec {
    width: 100%;
  }
  .grid-modal-main .line-thickness {
    margin: 10px 0px 0px 0px;
  }
  .grid-modal-main .form-group {
    width: 50%;
  }
  .grid-modal-main .cus-input-grp .cus-lable {
    margin: 10px 0px 15px 0px;
  }
  .check-color-pick-sec {
    width: 50%;
    padding-top: 5px;
  }
  .line-color {
    margin-top: 10px;
  }
  .filter-btn-main .filter-btn {
    top: 48px;
  }
  .btn-bg .reset-ic {
    width: 18px;
  }
  .download-chart-date {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal-body {
    width: 300px !important;
    max-height: 380px;
    overflow: auto;
  }
  .header-left h6 {
    font-size: 12px;
    line-height: 20px;
  }
  .editRepot-download-sec {
    display: flex;
  }
  .modal-wrapper {
    width: 300px !important;
  }
  .drawing-modal-wrapper {
    width: 135px !important;
  }
  .horizon .drawing-modal-wrapper {
    width: 300px !important;
  }
  .modal-ul-li .default-model-ul {
    gap: 10px;
  }
  .default-setting-main .time-frame-content {
    display: block;
  }
  .name-wrap .tag-main .last-child {
    margin-right: 8px;
  }
  .name-wrap .tag-main {
    padding-left: 9px 9px;
  }
  .market-list-main {
    max-width: 50%;
  }
  .script-list-main {
    max-width: 50%;
  }
  .rdrMonthAndYearWrapper {
    height: 10px;
  }
  .responsive-date-range-picker {
    width: 70% !important;
    margin: 10px auto;
  }
  .switch-box {
    width: 130px;
  }
  .chart-view-sec .chart-view-grid {
    margin-bottom: 20px;
  }
  .category-select-box .common-dropdown {
    min-width: 102px;
    width: 102px;
    padding: 5px 0px 7px 5px;
  }
}

@media screen and (min-height: 1079px) {
  .sidebar-wraqpper {
    flex-direction: row;
  }
  .sidebar-top::before,
  .sidebar-bottom::before {
    border: 0;
  }
  .siebar-inner-wrapper {
    border-left: 1px solid var(--sidebar-border);
  }
}

@media (max-width: 768px) {
  .responsive-date-range-picker {
    width: 85%;
    margin: 10px auto;
  }
  .date-range-btns {
    width: 85%;
  }
}

@media (max-width: 525px) {
  .stocks-list-wrapper .stocks-list-heading-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .index-list-heading-main {
    width: 100%;
    text-align: start;
  }

  .stocks-list-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
@media (max-width: 640px) {
  .tab-content-main .tab-hidden {
    display: none;
  }

  .default-setting-main .tabs-content-title {
    width: calc(100% / 3);
  }
}

@media (max-width: 480px) {
}
@media (max-width: 380px) {
}
